import React, { useState } from 'react';
import { FaChevronDown, FaMapPin } from "react-icons/fa";


import '../styles/Country.css';

export default function Country() {
    const [ country, setCountry ] = useState({
        name: 'Curacao',
        image: 'https://e22hgs9rtwc.exactdn.com/wp-content/uploads/2021/11/shutterstock_2262892975.jpg?strip=all&lossy=1&ssl=1',
        intro: {
            title: 'Curacao Travel Guide',
            body: "Discover the vibrant beauty of Curaçao, an island where the shimmering turquoise waters of the Caribbean Sea gently kiss the shores of pristine, white-sand beaches. Picture yourself wandering through the charming streets of Willemstad, the island's capital, where rows of colorful colonial buildings, painted in shades of pastel pinks, yellows, and blues, create a stunning contrast against the bright blue sky. Every corner of this UNESCO World Heritage city tells a story, from its rich history to its vibrant cultural scene."
        },
        blogs: [
            {
                id: 1,
                name: 'BLUE BAY BEACH',
                desc: 'Blue Bay Beach in Curaçao is a stunning tropical haven known for its crystal-clear waters, soft white sand, and vibrant coral reefs.',
                author: 'JANICE TJON',
                image: 'https://images.ctfassets.net/m868ks80jg7q/60Ge4jrqONABiaI3PffWRm/7a572b8e0459a9161410ecdcbcd39c3e/cc-blue-bay-beach.jpg?w=600&h=415&fm=webp&fit=fill&q=75'
            },
            {
                id: 2,
                name: 'CASCADA ROOFTOP BAR + KITCHEN',
                desc: "Cascada Rooftop Bar + Kitchen is Curaçao's most stunning rooftop experience, bar, and lounge! Enjoy 360° views…",
                author: 'ELIZABETH HEATH',
                image: 'https://www.cascadacuracao.com/lib/eh2m1o/Elements---Drone-1-lrgvedqx.jpg'
            },
        ]

    })

    const [ hotelIndex, setHotelIndex ] = useState(0);
    const [ hotels, setHootels ] = useState([
        {
            title: "Acoya Curacao Resort",
            location: "Curaçao, Willemstad",
            description: "ACOYA Curacao Resort Villas and Spa is an environmentally-friendly upscale hotel with modern Rooms, spacious and luxuriously outfitted Villas and Apartments, Restaurants, Spa and Dive Center near some of the most beautiful beaches and exciting tourist attractions in Curaçao. Our spacious and comfortable Villas and Apartments are tranquil, family-friendly retreats with a wide array of resort amenities and services, all in an atmosphere of gracious hospitality. Designed for short and extended stay …",
            button: "Book Now",
            image: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/2c/04/b2/a4/caption.jpg?w=700&h=-1&s=1',
        },
        {
            title: "Ananda Curacao",
            location: "Curaçao, Willemstad",
            description: "Secluded in the valley of Villa Park Fontein, it offers complete privacy and free from traffic noise and other sounds from the city. It's the perfect place to hold business, celebrations, business meetings, ventures or booth camp. With its own theater and labyrinth to make a more comfortable setting for inspiration. Yoga retreats, mindfulness workshops, cooking classes or healing ventures will definitely have added value with Ananda Wellness Retreat Curacao. We offer 8 special designed luxurious villas. Each with private balcony, fully equipped kitchen, cable, free wi-fi with a view at nature. ",
            button: "Book Now",
            image: 'https://ananda-wellness-retreat-villa-resort.comcaribbean.com/data/Imgs/OriginalPhoto/7658/765873/765873100/img-ananda-wellness-retreat-villa-resort-fontein-1.JPEG',
        },
        {
            title: "Avila Beach Hotel",
            location: "Curaçao, Willemstad",
            description: "The Avila Beach Hotel is a centrally located luxury beachfront resort in the Pietermaai District of Curaçao with two private beaches, cultural and culinary events, and an authentic soul. It is a house, a home, your favorite bar, your first choice for dinner, your beach, your escape and your good friend. Whether for business, romance or simple pleasure, the Avila Beach Hotel is not only a location but a favorite destination for many sophisticated travelers around the world. Experience, Enjoy and Taste Curaçao the Avila way. Avila Beach Hotel - Feel the Warmth.",
            button: "Book Now",
            image: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1c/de/3e/1a/aerial-view-avila-beach.jpg?w=700&h=-1&s=1',
        },
        {
            title: "Baoase Luxury Resort",
            location: "Curaçao, Willemstad",
            description: "Surrounded by the crystal clear waters of the Caribbean Sea, Baoase Luxury Resort is situated in the South of Curaçao. With its meandering free-form pools, waterfalls, swaying palm trees, and an idyllic private beach and lagoon, luxury becomes limitless. Baoase strives to give you an unforgettable vacation experience, from the moment of check-in to the moment of check-out. With Baoase Culinary Beach Restaurant in the heart of it all, the resort has everything you need for a paradise stay.",
            button: "Book Now",
            image: 'https://baoase.com/wp-content/uploads/2024/03/Baoase-Villas.jpg',
        },
        {
            title: "Baoase Luxury Resort",
            location: "Curaçao, Willemstad",
            description: "Surrounded by the crystal clear waters of the Caribbean Sea, Baoase Luxury Resort is situated in the South of Curaçao. With its meandering free-form pools, waterfalls, swaying palm trees, and an idyllic private beach and lagoon, luxury becomes limitless. Baoase strives to give you an unforgettable vacation experience, from the moment of check-in to the moment of check-out. With Baoase Culinary Beach Restaurant in the heart of it all, the resort has everything you need for a paradise stay.",
            button: "Book Now",
            image: 'https://ananda-wellness-retreat-villa-resort.comcaribbean.com/data/Imgs/OriginalPhoto/7658/765873/765873100/img-ananda-wellness-retreat-villa-resort-fontein-1.JPEG',
        },
        {
            title: "Baoase Luxury Resort",
            location: "Curaçao, Willemstad",
            description: "Surrounded by the crystal clear waters of the Caribbean Sea, Baoase Luxury Resort is situated in the South of Curaçao. With its meandering free-form pools, waterfalls, swaying palm trees, and an idyllic private beach and lagoon, luxury becomes limitless. Baoase strives to give you an unforgettable vacation experience, from the moment of check-in to the moment of check-out. With Baoase Culinary Beach Restaurant in the heart of it all, the resort has everything you need for a paradise stay.",
            button: "Book Now",
            image: 'https://baoase.com/wp-content/uploads/2024/03/Baoase-Villas.jpg',
        },
    ])

    const [ restaurantIndex, setRestaurantIndex ] = useState(0);
    const [ restaurants, setRestaurants ] = useState([
        {
            title: "Baoase Beach Club",
            location: "Curaçao, Willemstad",
            description: "Baoase, a truly unique experience. Situated directly on the resort's beautiful white, sandy and exclusive beach - overlooking the turquoise Caribbean waters and the resort's own private island - Baoase offers you a breathtaking experience.",
            button: "Book Now",
            image: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/22/ff/8b/a0/gazebo.jpg?w=1200&h=-1&s=1",
        },
        {
            title: "Blues Bar & Restaurant",
            location: "Curaçao, Willemstad",
            description: "Located on its own ocean pier at the renowned Avila Beach Hotel, the Blues Bar & Restaurant serves hot food and cool music for all to enjoy. For tasty barbecued specialities grilled to perfection, all your favorite drinks, and the best Jazz & Blues music and a breathtaking 360° ocean view, Blues Bar & Restaurant is the place to be for BBQ lovers and both local and international jazz fans.",
            button: "Book Now",
            image: "https://media-cdn.tripadvisor.com/media/photo-s/1b/05/6d/d2/blues-bar-and-restaurant.jpg",
        },
        {
            title: "Brass Boer Curaçao",
            location: "Curaçao, Willemstad",
            description: "Discover Brass Boer Curaçao, an oceanfront restaurant offering culinary excellence at night and daytime relaxation. Enjoy our diverse lunch menu and experience a blend of European and Caribbean flavours at sunset, accompanied by fine wines. The ideal destination for daytime beach enjoyment and evening culinary delight.",
            button: "Book Now",
            image: "https://www.brassboer.com/curacao/wp-content/uploads/sites/4/2023/09/377A8279-1-1280x853.jpg",
        },
        {
            title: "Chocogo Terrace",
            location: "Curaçao, Willemstad",
            description: "At Restaurant Chocogo Terrace you can enjoy fair priced delicious dishes while you are overlooking the swimming pool and the Caribbean Sea. The restaurant offers you daily breakfast, lunch and dinner. Their dedicated staff uses a large, varied menu and only works with fresh ingredients. At special nights they offer fun barbecues and live music. Of course, you can also just enjoy a drink at the cozy bar.",
            button: "Book Now",
            image: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0e/4e/5e/64/view-while-enjoying-the.jpg?w=1200&h=-1&s=1",
        },
        {
            title: "Blues Bar & Restaurant",
            location: "Curaçao, Willemstad",
            description: "Located on its own ocean pier at the renowned Avila Beach Hotel, the Blues Bar & Restaurant serves hot food and cool music for all to enjoy. For tasty barbecued specialities grilled to perfection, all your favorite drinks, and the best Jazz & Blues music and a breathtaking 360° ocean view, Blues Bar & Restaurant is the place to be for BBQ lovers and both local and international jazz fans.",
            button: "Book Now",
            image: "https://media-cdn.tripadvisor.com/media/photo-s/1b/05/6d/d2/blues-bar-and-restaurant.jpg",
        },
        {
            title: "Blues Bar & Restaurant",
            location: "Curaçao, Willemstad",
            description: "Located on its own ocean pier at the renowned Avila Beach Hotel, the Blues Bar & Restaurant serves hot food and cool music for all to enjoy. For tasty barbecued specialities grilled to perfection, all your favorite drinks, and the best Jazz & Blues music and a breathtaking 360° ocean view, Blues Bar & Restaurant is the place to be for BBQ lovers and both local and international jazz fans.",
            button: "Book Now",
            image: "https://media-cdn.tripadvisor.com/media/photo-s/1b/05/6d/d2/blues-bar-and-restaurant.jpg",
        },
    ])

    const [ activeItem, setActiveItem ] = useState(0);
    const [ experiences, setExperiences ] = useState([
        {
            id: 1,
            title: "CURAÇAO MUSEUM",
            body: "Located in Otrobanda's western district, this colonial-style museum showcases Curaçao's rich heritage through geological exhibits, pre-Columbian Indian artifacts, and Caribbean historical collections. Features authentic 18th and 19th century mahogany furniture, historical maps, and artworks from local and international artists. Special focus on afro-Curaçaoan culture post-slavery abolition."
            },
        {
            id: 2,
            title: "CURAÇAO ART",
            body: "A vibrant cultural hub showcasing contemporary and traditional Curaçaoan artistic expressions. Houses rotating exhibitions featuring prominent Caribbean artists, sculpture gardens, and interactive art installations that reflect the island's diverse cultural heritage."
        },
        {
            id: 3,
            title: "FORTCHURCH LIVING MONUMENT",
            body: "Historic fortified church structure serving as both a religious site and cultural landmark. Represents a unique blend of Dutch colonial architecture and Caribbean influences. Offers guided tours highlighting its role in Curaçao's religious and military history."
        },
        {
            id: 4,
            title: "LAMAN SPA AND WELLNESS",
            body: "Premium wellness destination offering traditional Caribbean healing treatments and modern spa therapies. Features ocean-view treatment rooms, natural local ingredients, and specialized relaxation programs inspired by island traditions."
        },
        {
            id: 5,
            title: "DISTRICT OTROBANDA",
            body: "Historic neighborhood known for its colorful Dutch colonial architecture and winding streets. Home to numerous cultural attractions, local markets, and authentic restaurants. Represents one of Curaçao's most well-preserved historic districts with stunning harbor views."
        }
    ])

    const handleNext = () => {
        if (hotelIndex < hotels.length - 4) {
            setHotelIndex(hotelIndex + 1);
        }
    };

    const handlePrev = () => {
        if (hotelIndex > 0) {
            setHotelIndex(hotelIndex - 1);
        }
    };

    const handleNextRes = () => {
        if (restaurantIndex < restaurants.length - 4) {
            setRestaurantIndex(restaurantIndex + 1);
        }
    };

    const handlePrevRes = () => {
        if (restaurantIndex > 0) {
            setRestaurantIndex(restaurantIndex - 1);
        }
    };

    const renderBlogs = () => {

        const render_blogs = country.blogs.map((blog, index) => {

            return (
                <div className='country-blog' key={index}>
                    <div className='blog-image' style={{backgroundImage: `url(${blog.image})`, backgroundSize: 'cover'}}></div>
                    <div className='blog-info'>
                        <div className='blog-info-title'>{blog.name}</div>
                        <div className='blog-info-body'>{blog.desc}</div>
                        <div className='blog-info-author'>WRITTEN BY <span>{blog.author}</span></div>
                    </div>
                </div>
            )

        })

        return render_blogs;

    }

    const renderHotels = () => {

        const render_hotels = hotels.map((hotel, index) => {
            return (
                <div className='hotel-item' >
                    <div className='hotel-image' style={{backgroundImage: `url(${hotel.image})`, backgroundSize: 'cover'}}></div>
                    <div className='hotel-title'>{hotel.title}</div>
                    <div className='hotel-location'>
                        <FaMapPin size={12} color={'#2c2c2c'} /> 
                        <div className='hotel-address'>{hotel.location.toUpperCase()}</div>
                    </div>
                    <div className='hotel-descr'>{hotel.description}</div>
                    <div className='hotel-btn'>{hotel.button.toUpperCase()}</div>
                </div>
            )
        })

        return (
            <div className='hotels-track' style={{transform: `translateX(-${hotelIndex * 20}%)`}}>
                {render_hotels}
            </div>
        )

    }

    const renderRestaurants = () => {

        const render_restaurants = restaurants.map((restaurant, index) => {
            return (
                <div className='hotel-item' >
                    <div className='hotel-image' style={{backgroundImage: `url(${restaurant.image})`, backgroundSize: 'cover'}}></div>
                    <div className='hotel-title'>{restaurant.title}</div>
                    <div className='hotel-location'>
                        <FaMapPin size={12} color={'#2c2c2c'} /> 
                        <div className='hotel-address'>{restaurant.location.toUpperCase()}</div>
                    </div>
                    <div className='hotel-descr'>{restaurant.description}</div>
                    <div className='hotel-btn'>{restaurant.button.toUpperCase()}</div>
                </div>
            )
        })

        return (
            <div className='hotels-track' style={{transform: `translateX(-${restaurantIndex * 20}%)`}}>
                {render_restaurants}
            </div>
        )

    }

    const toggleItem = (id) => {
        setActiveItem(id === activeItem ? null : id);
    };

    const renderExperiences = () => {

        const render_experiences = experiences.map((exp, index) => {
            return (
                <div
                key={exp.id}
                className={`attraction-item ${exp.id === activeItem ? 'active' : ''}`}
                onClick={() => toggleItem(exp.id)}
                >
                    <div className='attraction-header'>{exp.title}</div>
                    <div className="attraction-body">{exp.body}</div>
                </div>
            )
        })

        return (
            <div className='curaçao-attractions-menu'>
                {render_experiences}
            </div>
        );

    }

  return (
    <div className=''>
        <div className='landing-section' style={{backgroundImage: `url(${country.image})`, backgroundSize: 'cover'}}>
            
        </div>
        <div className='country-intro'>
            <div className='country-intro-title'>{country.intro.title}</div>
            <div className='country-intro-body'>{country.intro.body}</div>
            <FaChevronDown size={24} color={'#2c2c2c'} style={{marginTop: '20px'}} />
        </div>

        <div className='country-section-header'>Plan your Curacao Trip</div>
        <div className='country-blogs'>
            {renderBlogs()}
        </div>

        <div className='country-section-header'>Explore Hotels</div>
        <div className='country-hotels'>
            <button className="carousel-arrow left" onClick={handlePrev}>‹</button>
            {renderHotels()}
            <button className="carousel-arrow right" onClick={handleNext}>›</button>
        </div>
        
        <div className='country-section-header'>The best places to dine in Curacao</div>
        <div className='country-hotels'>
            <button className="carousel-arrow left" onClick={handlePrevRes}>‹</button>
            {renderRestaurants()}
            <button className="carousel-arrow right" onClick={handleNextRes}>›</button>
        </div>

        <div className='country-experiences-container'>
            <div className='country-section-header alt'>Experiences you can't miss</div>
            <div className='exp-container'>
            
                {renderExperiences()}

                <div className='exp-image-wrapper'>
                    <img className='exp-image' src={'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/08/75/2e/8d/museo-curacao.jpg?w=1200&h=-1&s=1'} />
                </div>
            </div>
        </div>
    </div>
  )
}
