import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { CookiesProvider, useCookies } from 'react-cookie';
import { useState } from 'react';

import Navbar from './navigation/Navbar';
import Footer from './navigation/Footer';

import Toasty from './components/Toasty';

import Home from './views/Home';
import Construction from './views/Construction';

import './App.css';
import Country from './views/Country';

function App() {

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSuccess, setToastSuccess] = useState(false);

  const triggerToast = (message, success) => {
    setToastMessage(message);
    setToastSuccess(success);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000); // Toast disappears after 3 seconds
  };



  return (
    <CookiesProvider>
      <Router>
        <Navbar/>
        <Toasty showToast={showToast} message={toastMessage} toastSuccess={toastSuccess}/>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/country' element={<Country/>} />
          
          {/* <Route path="/" element={<Construction triggerToast={triggerToast}/>} /> */}
        </Routes>
        <Footer />
      </Router>
    </CookiesProvider>
  );
}

export default App;
