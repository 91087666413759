import React, { useState } from 'react';

import '../styles/Home.css';
import { MdChevronRight } from 'react-icons/md';

export default function Home() {
  const [articles, setArticles] = useState([
    {
      id: 1,
      title: "Jade Mountains: Nature's Emerald Sculptures",
      description: "Rising majestically against the horizon, the Jade Mountains are a stunning testament to the raw beauty of nature. Olivia Martinez takes you on a journey through these breathtaking ...",
      author: "Olivia Martinez",
      image: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/356787622.jpg?k=1ad839e968b1ee3b2ed211f2bebb76fd3c9caa7f4d95764fbb5368c9196b9a21&o=&hp=1"
    },
    {
      id: 2,
      title: "Shopping Adventures in the Caribbean",
      description: "Shopping in the Caribbean is an experience that blends vibrant culture, unique craftsmanship, and the thrill of discovery. In Tropical Treasures: Shopping Adventures in the ...",
      author: "Jessica Monroe",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV3g0woSxiOW3Exn3piW0UY5Lsgr9lXPBKPQ&s"
    },
    {
      id: 3,
      title: "A Culinary Journey Through the Caribbean",
      description: "The Caribbean is a melting pot of flavors, where the fusion of cultures, histories, and traditions is most vividly expressed through its food. In A Culinary Journey Through the Caribbean: Flavors of Paradise, Marcus Bennett explores the vibrant ...",
      author: "Marcus Bennett",
      image: "https://www.b3designers.co.uk/media/kzbi3h3j/b3designers_caribbeansmokehouse_006.jpg?anchor=center&mode=crop&width=1440&height=930&rnd=132373855949230000"
    },
    {
      id: 4,
      title: "Caribbean Dreams: Exploring the Heart of Paradise",
      description: "The Caribbean is a region that evokes visions of crystal-clear waters, white sandy beaches, and vibrant cultures. But beyond its postcard-perfect beauty lies a rich tapestry of history, traditions, and hidden gems waiting to be discovered.",
      author: "David Oswena",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvF1OJW4OVaOQyoMRd_S0CoA208dGXj-9RZA&s"
    }
  ])
  const [destinations, setDestinations] = useState([
    {
      id: 1,
      country: 'Curacao',
      image: 'https://e22hgs9rtwc.exactdn.com/wp-content/uploads/2021/11/shutterstock_2262892975.jpg?strip=all&lossy=1&ssl=1'
    },
    {
      id: 2,
      country: 'Aruba',
      image: 'https://media-cdn.tripadvisor.com/media/photo-s/2c/09/6e/ee/cabanas-aerial.jpg'
    },
    {
      id: 3,
      country: 'Bonaire',
      image: 'https://www.sante.nl/wp-content/uploads/2023/07/bonaire-van-europa-2024.jpg'
    },
  ])

  const renderArticles = () => {
    const highlight = articles[0];


    const list_articles = articles.filter((article, index) => index > 0).map((article, index) => {

      return (
        <div className='article-list-item'>
          <div className='article-item-image' style={{backgroundImage: `url(${article.image})`, backgroundSize: 'cover'}}></div>
          <div className='article-item-info'>
            <div> 
              <div className='article-item-title'>{article.title}</div>
              <div className='article-item-description'>{article.description}</div>
            </div>
            
            <div className='article-item-author'>Written by {article.author}</div>
          </div>
        </div>
      )

    })

    return (
      <div className='articles'>
        <div className='articles-highlight' style={{backgroundImage: `url(${highlight.image})`, backgroundSize: 'cover'}}>
          <div className='article-highlight-overlay'>
            <div className='article-highlight-title'>{highlight.title}</div>
            <div className='article-highlight-description'>{highlight.description}</div>
            <div className='article-highlight-author'>Written by {highlight.author}</div>
          </div>
        </div>
        <div className='articles-list-container'>
          {list_articles}
        </div>

      </div>
    )


  }

  const renderDestinations = () => {

    const render_destinations = destinations.map((destination, index) => {

      return (
        <div className='destination' style={{backgroundImage: `url(${destination.image})`, backgroundSize: 'cover'}}>
          <div className='destination-cover'>
            <div className='destination-name'>{destination.country}</div>
          </div>
        </div>
      )
    })

    return (
      <div className='destinations-container'>
        { render_destinations }
      </div>
    )

  }

  return (
    <div>
      <div className='landing-section' style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/landing-bg.png'})`, backgroundSize: 'cover'}}>
        <div className='landing-overlay'>
          <div className='landing-text'>
            WEAVING YOUR DREAMS <br/>
            INTO UNFORGETTABLE <br/>
            ADVENTURES
          </div>
        </div>
      </div>

      <div className='home-about-container'>
        <div style={{display: 'flex'}}>
          <div className='home-about-title'>OUR STORY</div>
          <div className='home-about-description'>Outcaribbean emerged from a vision to create a safe, inclusive space for LGBTQ travelers exploring the vibrant tapestry of the Caribbean. Founded in 2020 , we recognized the need for a platform that speaks directly to the LGBTQ community's unique travel experiences and concerns. Our mission is to empower and inspire queer travelers to discover the beauty, culture, and warmth of the Caribbean while navigating the complexities of LGBTQ acceptance in the region. </div>
        </div>
        
        <div className='home-about-options'>
          <div className='home-about-option'>
            Read More
            <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
              <MdChevronRight size={20} color='#2c2c2c'/>
            </div>
          </div>
        </div>
      </div>
      
      <div className='container'>
        <div className='section-title'>Articles</div>

        {renderArticles()}
      </div>

      <div className='container'>
        <div className='section-title'>Destinations</div>
        { renderDestinations() }
      </div>
    </div>
  )
}
