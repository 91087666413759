import React from "react";
import { FaCheckCircle, FaWindowClose } from "react-icons/fa";

import '../App.css';

function ToastMessage({ showToast, message, toastSuccess }) {
  return (
    <>
      {showToast && (
        <div className="toast">
          <span className="toast-icon">
            { (toastSuccess) ?
              <FaCheckCircle color="#fff"/>
              
              :

              <FaWindowClose color="#fff"/>
            }
          </span>
          <span className="toast-message">{message}</span>
        </div>
      )}
    </>
  );
}

export default ToastMessage;