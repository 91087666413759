import React from 'react';

import '../styles/Navbar.css';
import '../styles/Responsive.css';

import { MdMenu, MdSearch } from "react-icons/md";
import { Link } from 'react-router-dom';

export default function Navbar() {
  return (
    <div className='nav-wrapper'>
        <div className='nav-logo-container'>
            <img src={process.env.PUBLIC_URL + '/assets/logo.png'} className='nav-logo'/>
        </div>
        <div className='nav-items-container'>
          <Link className='nav-item'>INSPIRATION</Link>
          <Link className='nav-item' to={'/country'}>DESTINATIONS</Link>
          <Link className='nav-item'>PLACES TO STAY</Link>
          <Link className='nav-item'>NEWS & ADVICE</Link>
          <Link className='nav-item'>DIRECTORY</Link>
        </div>

        <div className='nav-options-container'>
          <div className='nav-option'>
            <MdMenu size={25} color='#2c2c2c'/>
          </div>
          <div className='nav-option' style={{marginLeft: '20px'}}>
            <MdSearch size={25} color='#2c2c2c' className='nav-option'/>
          </div>
        </div>
    </div>
  )
}
